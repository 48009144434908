 
 import 'bootstrap/dist/css/bootstrap.css'; 
 import './Navbar.css';  
 import React, { useState, useEffect } from 'react';
 import {Link} from 'react-router-dom'; 
//  import logo from '../logo.png'; 
//  import logo1 from '../logo-2.png';
 import { 
  Container, 
  Dropdown,
  NavDropdown,
  Col,
  Row,
} from "react-bootstrap";

function Navbar() { 

 const [click, setClick]  = useState(false);
//  const [navbar, setNavbar]  = useState(false);
 
 const [button, setButton]  = useState(true);
 const handleClick = () =>setClick(!click);
 const closeMobileMenu = () => setClick(false);
 
 const showButton = () => {
   if(window.innerWidth <= 960){
     setButton(false);
   }else{
    setButton(true);

   }

 };

 useEffect(() => {
  showButton(); 
 },[]);

 window.addEventListener('resize', showButton);

//  const changeBackground = () => { 
// if(window.scrollY >= 80){
//   setNavbar(true);
// }else{
//   setNavbar(false);
// } }; 

// window.addEventListener('scroll', changeBackground);
  
  return (
     <nav className='navbar row'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src={button? '/images/logo/logo.png': '/images/logo/logo-2.png'} alt="Mina Furniture"  height="60px" />  
          </Link>
          <div className='clearfix'></div>
            <span  className="social-group">
            <Link to={{pathname:"https://www.facebook.com/Minafurnitur/"}} target="_blank">
            <img src="/images/icons/facebook-icon.png" alt="" className="social-link"></img>
            </Link>
            <Link to={{pathname:"https://t.me/minafurniture"}}  target="_blank">
              
            <img src="/images/icons/telegram-icon.png" alt="" className="social-link"></img>
            </Link>
            <Link to={{pathname:"https://vm.tiktok.com/ZMRrhV6je/"}}  target="_blank">              
            <img src="/images/icons/tiktok-icon.png" alt="" className="social-link" ></img>
            </Link>
          </span> 
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}> 
            <li className='nav-item'>
                <NavDropdown
                    className="pr-2 py-2 align-text-top"
                    title="Products" id="basic-nav-dropdown">
                    <Container className="productNav pt-0 mt-0">
                      <Row>
                        <Col xs="12" md="6" className="text-left">
                          <Dropdown.Header>  
                            Home Furnitures
                          </Dropdown.Header>
                          <Dropdown.Item>
                            <Link to="/products/dining-table"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                Dining Table 
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link to="/products/sofa" 
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                Sofa                             
                            </Link>
                          </Dropdown.Item>   
                          <Dropdown.Item>
                            <Link to="/products/coffee-table"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                Coffee Table 
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link to="/products/bed"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                Bed 
                            </Link>
                          </Dropdown.Item>
                          
                          <Dropdown.Item>
                            <Link to="/products/kitchen-cabins"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                Kitchen Cabins
                            </Link>
                          </Dropdown.Item> 
                          <Dropdown.Item>
                            <Link to="/products/arebian-mejilis"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                               Arebian Mejilis
                            </Link>
                          </Dropdown.Item> 

                          {/* <Dropdown.Divider /> */}
                          {/* <Dropdown.Header>  
                            New Products
                          </Dropdown.Header>
                          <Dropdown.Item>
                            <Link to="/"
                             className='nav-links'
                             onClick={closeMobileMenu}>
                                Home
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link to="/"
                             className='nav-links'
                             onClick={closeMobileMenu}>
                                Office
                            </Link>
                          </Dropdown.Item>
    */}
                          <Dropdown.Divider className="d-md-none" />
                        </Col>

                        <Col xs="12" md="6" className="text-left">
                          <Dropdown.Header>   
                            Office Furnitures
                          </Dropdown.Header>
                          <Dropdown.Item>
                            <Link to="/products/meeting-tables"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                Meeting Tables                             
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link to="/products/shelve"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                Shelve
                            </Link>
                          </Dropdown.Item>
                          
                          <Dropdown.Item>
                            <Link to="/products/public-tables"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                Public Tables
                            </Link>
                          </Dropdown.Item>
                          
                          <Dropdown.Item>
                            <Link to="/products/file-cabines"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                File Cabines
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link to="/products/office-sofa"
                             className='nav-links' 
                             onClick={closeMobileMenu}>
                                Office Sofa
                            </Link>
                          </Dropdown.Item>
                          {/* <Dropdown.Divider />
                          <Dropdown.Header>  
                            Luxury Products
                          </Dropdown.Header>
                          <Dropdown.Item>
                            <Link to="/"
                             className='nav-links'
                             onClick={closeMobileMenu}>
                                Home                            
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link to="/"
                             className='nav-links'
                             onClick={closeMobileMenu}>
                                Office
                            </Link>
                          </Dropdown.Item> */}
                        </Col>
                      </Row>
                    </Container>
                  </NavDropdown>
                </li>  
          
            <li className='nav-item'>
              <Link
                to='/news'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                News
              </Link>
            </li> 
            <li className='nav-item'>
              <Link
                to='/about'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About
              </Link>
            </li>  
            <li className='nav-item'>
              <Link
                to='/contactus'
                className='nav-links'
                onClick={closeMobileMenu}>
                Contact us
              </Link>
            </li>           
          </ul> 
        </div>
        <div> 
        </div>
      </nav>
    
  );
}

export default Navbar;