import {React, useState} from 'react';
import { useTheme } from '@mui/material/styles'; 
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl'; 
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem'; 

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 0;
const gurdshola = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.478999335615!2d38.811514814458334!3d9.019993491614352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8523b875e4a3%3A0x776d43344fe17a02!2zTWluYSBGdXJuaXR1cmUgfCDhiJrhipMg4Y2I4Yit4YqS4Ym44Yit!5e0!3m2!1sen!2set!4v1634397160884!5m2!1sen!2set";
const jemo1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12512.279215950231!2d38.701173914374124!3d8.960987563711345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8122a339b649%3A0xec5afd8f9ab52143!2sMina%20Furniture!5e0!3m2!1sen!2set!4v1631551745055!5m2!1sen!2set";
const jemo2 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.1378682445825!2d38.70681331445793!3d8.959441492542213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8122a339b649%3A0xec5afd8f9ab52143!2sMina%20Furniture!5e0!3m2!1sen!2set!4v1634397462099!5m2!1sen!2set";
 
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const shops = [
    '#1 Jemo',
    '#2 Jemo',
    '#3 Gurdshola'
];

const styles = { 
    separator:{
        paddingTop: "20px"
    },
    shopSelector:{
        width: "300px"
    },  
  };


function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

function GoogleMap(props) {
    const theme = useTheme();
    const [shopName, setShopName] = useState([]);
    const [shopAddress, setShopAddress] = useState(jemo1);

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setShopName( value);
        console.log(value);
        switch (value){  
          case '#1 Jemo': 
            setShopAddress(jemo1);
            break;
          case  '#2 Jemo':
            setShopAddress(jemo2);
            break;
          case   '#3 Gurdshola':
            setShopAddress(gurdshola);
            break;
          default:
            break;
        } 
      };
  return (
      <div>
            <div style={styles.separator}></div>
          
            <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="shopping-address-label">Shopping Address</InputLabel>
        <Select
          labelId="shop"
          id="shop-name" 
          value={shopName}
          onChange={handleChange}
          input={<OutlinedInput label="Shop" />}
          MenuProps={MenuProps}
        >
          {shops.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, shopName, theme)}
            >
              {name}g
            </MenuItem>
          ))}
        </Select>
      </FormControl> 

    <div style={styles.separator}></div>
        <div className="google-map-code">
        <iframe src={shopAddress} title="Mina Furniture Shopping Address" width="100%" height="450" frameBorder="0" style={{border:0}} aria-hidden="false" tabIndex="0" allowFullScreen="" loading="lazy"></iframe>
      </div>
    </div>
  );
}

export default GoogleMap;