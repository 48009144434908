import * as React from 'react';  
import News from '../components/News'; 


const styles = {
    
    newsHeader:{  
            background: "url('/images/img-home2.jpg') center center/cover no-repeat",
            height: "340px" ,
            marginTop: "0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow:" inset 0 0 0 1000px rgba(0, 0, 0, 0.2)",
            objectFit: "contain", 
    },
    headerText: {
        color: "#fdec09",
        fontSize: "40px",
        fontWeight: "bold"
    }, 
  };


 
const NewsPage = () => {  
  return ( 
      <>
        <div className="row">
            <div style={styles.newsHeader}> 
                <h1 style={styles.headerText}> News and Updates</h1>  
            </div> 
        </div> 
        <News/>
      </>
  );
}

export default NewsPage;