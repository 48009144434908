import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';  
import Grid from '@mui/material/Grid';

function Footer() {
  return (
    <div className="row">
    <div className='footer-container'>
      <div className='row'>
          <section className='col-md-12 footer-subscription'>
            <p className='footer-subscription-heading'>
              Join our newsletter to receive the latest information!
            </p>
            <p className='footer-subscription-text'>
              You can unsubscribe at any time.
            </p>
            <div className='input-areas'>
              <form>
                <input
                  className='footer-input'
                  name='email'
                  type='email'
                  placeholder='Your Email'
                />
                <Button buttonStyle='btn--outline'>Subscribe</Button>
              </form>
            </div>
          </section>
      </div> 
      <div className='row'> 
      <Grid container spacing={2} className=""> 
        <Grid  item   xs={6} sm={6} md={3} lg={3}>
        <div className='footer-link-items'>
        <h3>Company</h3>
            <Link to='/about'>About us</Link>
            <Link to='/'>Blogs</Link>
            <Link to={{ pathname: "https://www.termsfeed.com/live/283672c5-23e7-4d8b-8749-caacba71cab8" }} target="_blank">Privacy Policy</Link> 
        </div>
        </Grid>
        <Grid  item  xs={6} sm={6} md={3} lg={3}>
        <div className='footer-link-items'>
        <h3>Product</h3>
            <Link to='/'>Pricing</Link>
            <Link to='/'>Features</Link>
            <Link to='/'>Customers</Link>
            <Link to='/'>Sponsorships</Link>
        </div>
        </Grid>
        <Grid  item   xs={6} sm={6} md={3} lg={3}>
        <div className='footer-link-items'>
        <h3>Helps</h3>
            <Link to='/'>Direction</Link>
            <Link to='/'>Delivery</Link>
            <Link to='/'>Referals</Link>
            <Link to='/'>Warranty</Link>
        </div>
        </Grid>
        <Grid  item  xs={6} sm={6} md={3} lg={3}>
        <div className='footer-link-items'>
        <h3>Contacts</h3>
            <a href='tel:+251 92424-5151'>+251 92424-5151</a>
            <a href='tel:+251 93434-5151'>+251 93434-5151</a>
            <a href='tel:+251 93838-5151'>+251 93838-5151</a>
            <a href='tel:+251 93232-5151'>+251 93232-5151</a>
            <a href='tel:+251 11838-7204'>+251 11838-7204</a>
        </div>
        </Grid>
      </Grid>   
      </div>
     
        <div className='row'>
         <div className='col-md-6 col-sm-12'>
          <div className='footer-link-items copyright'>
          <h4><span className='copyright mina'> Copyright &copy; 2021 - Mina Furniture</span></h4>
          </div>
         </div>
         <div className='col-md-6 col-sm-12'>
         <div className='footer-link-items copyright'>
         <h4><span className='copyright bilbala'> Designed by - <a href='https://habeshasoft.com' target="_blank" rel="noreferrer" className='bilbala'>Habeshasoft</a></span></h4>
          </div> 
         </div>
       </div>
     </div>
     </div>
   
  );
}

export default Footer;