import * as React from 'react';  
import CardContent from '@mui/material/CardContent'; 
import Grid from '@mui/material/Grid';  
import './News.css';  
import { CardActions } from '@mui/material';  
import Card from 'react-bootstrap/Card'; 
import Image from 'react-bootstrap/Image';

export default function News(props) {
    return (
        <div className="row news"> 
        <h3>{props.title}</h3>
         <Grid container spacing={4} className="gridContainer">   
        <Grid  item  xs={12} sm={6} md={4}  key={1}>
        <Card variant="outlined">
        <React.Fragment>
          <CardContent> 
          <Image src="/images/brand/brand-01.jpg" fluid  className="news-img"/>
          </CardContent> 
          <CardActions>
            <h4>Mina Furniture anounced new sofa design</h4></CardActions>
        </React.Fragment> 
          </Card>  
</Grid>   
        <Grid  item  xs={12} sm={6} md={4}  key={2}>
        <Card variant="outlined">
        <React.Fragment>
          <CardContent> 
          <Image src="/images/brand/brand-02.jpg" fluid  className="news-img"/>
          </CardContent> 
          <CardActions>
            <h4>Mina Furniture takes the fist Rank in Quality Furniture production</h4></CardActions>
        </React.Fragment> 
          </Card>  
</Grid>   
        <Grid  item  xs={12} sm={6} md={4}  key={3}>
        <Card variant="outlined">
        <React.Fragment>
          <CardContent> 
          <Image src="/images/brand/brand-03.jpg" fluid  className="news-img"/>
          </CardContent> 
          <CardActions>
            <h4>New Year Gift: Mina Furniture provides a great discount on ethiopian new year, 2014 EC.</h4></CardActions>
        </React.Fragment> 
          </Card>  
</Grid>  
</Grid>
        </div>
    );
}