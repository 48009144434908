//  import React, { useState, useEffect } from 'react'; 

const styles = {
    content: { 
      padding: "20px"
    },
    link: {  
            color: "#ffc107"
    },
    contactForm:{
        paddingLeft: "20px"
    },
    separator:{
        paddingTop: "20px"
    }, 
  };

function ContactForm(props) {  
    // const [submitted, setSubmitted]  = useState(false);   
    // const handleSubmit = () => { 
    //     setSubmitted(true); 
    // };
  
  return (
    <div>
         <form id="contact-form" style={styles.contactForm} method="POST">
          <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control" id="name" />
          </div>
          <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input type="email" className="form-control" id="email" aria-describedby="emailHelp" />
          </div>
          <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" rows="5" id="message" />
          </div>
          <div style={styles.separator}></div>
          <button type="submit" className="btn btn-warning">Submit</button>
        </form>
    </div>
  );
}

export default ContactForm;