import { useParams } from 'react-router-dom'
import * as React from 'react';  
import CardContent from '@mui/material/CardContent'; 
import Grid from '@mui/material/Grid';
import data from '../data/products.json'; 
import { CardActions } from '@mui/material';  
import Card from 'react-bootstrap/Card';
import ReactImageMagnify from 'react-image-magnify'; 
import "../components/HomeProducts.css"; 

const Products = () => {
    const { type } = useParams();
    return ( 
       <Grid container spacing={4} className="gridContainer">  
        {data[type].map((value, index) => { 
        return  <Grid  item  xs={12} sm={6} md={4}  key={index}>
          <Card variant="outlined">
              <React.Fragment>
                <CardContent>
                  <ReactImageMagnify {...{
                                      smallImage: {
                                          alt: 'Wristwatch by Ted Baker London',
                                          isFluidWidth: true,
                                          src: value.path,
                                          srcSet: "",
                                          sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                                      },
                                      largeImage: {
                                          src: value.path,
                                          width: 1200,
                                          height: 1800
                                      }
                                }} />
                </CardContent> 
                  <CardActions>
                     {/* <h4>{value.code}</h4> */}
                  </CardActions>
              </React.Fragment> 
            </Card>  
</Grid>  
      })} 
    
    </Grid>  
      );
}
 
export default Products;