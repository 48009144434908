  import * as React from 'react';  
  import AboutCard from '../components/AboutCard'; 
 

  const styles = {
    content: { 
      padding: "20px",
      marginLeft: "40px",
    }, 
    about: { 

        backgroundColor:"#f2f2f2"
    },
    aboutHeader:{  
            background: "url('/images/img-home.jpg') center center/cover no-repeat",
            height: "340px" ,
            marginTop: "0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow:" inset 0 0 0 1000px rgba(0, 0, 0, 0.2)",
            objectFit: "contain", 
    },
    headerText: {
        color: "#fdec09",
        fontSize: "40px",
        fontWeight: "bold"
    },
    aboutText:{
        fontSize: "18px",
        color: "#5a666d",
        marginBottom: "20px"
    },
    title:{
        fontSize: "20px"
    }
  };

const About = () => {  
    return ( 
        <div style={styles.about}>
        <div className="row">
            <div style={styles.aboutHeader}> 
                <h1 style={styles.headerText}> About Mina Furniture</h1>  
            </div> 
        </div> 
        <div className="row" style={styles.content}>
            <div className="col">
                <h2>Who is Mina Furniture?</h2>
                <p style={styles.aboutText} className="intro-text">
                In manufacturing we have continued to invest in new technology and machinery 
                to continue its popular pace with new designs and styles to suit clients' needs. 
                In particular, we have invested in post-forming machinery. 
                Technology to work with corian material as well as other woodworking machines.

                Having established itself as a market leader for office furniture, 
                the group has also continued its growth strategy and has developed 
                additional lines that includes kitchens and wardrobes, joinery & 
                fit outs and custom made furniture.  
                </p>
            </div>  
        </div>
        <div>
        <div className="row">
          <AboutCard/>
        </div>
        </div> 
      </div>
    );
}

export default About;