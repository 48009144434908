 import Card from 'react-bootstrap/Card';   
 import Grid from '@mui/material/Grid'; 
 import { FaCheckSquare } from 'react-icons/fa';
 import "./AboutCard.css";

 const styles = {
     icon: {
        color: "#24bbd9"
     }
 }
 
const AboutCard = () => {  
    return (  
        <Grid container spacing={2}> 
            <Grid  item  xs={12} sm={6} md={4}  key={1}>
                <Card style={{ width: '100%' }}>
                    <Card.Img variant="top" src="/images/about/about-01.png" />
                    <Card.Body>
                        <Card.Title><h2>Mission</h2></Card.Title> 
                        <p>We make furniture for life and take pride in our Danish roots and craftsmanship. Our mission is to create worth for our customers through sincere, pliability and honesty. 
                            We want to contribute to Living in Harmony for our customers. </p> 
                    </Card.Body>
                   </Card> 
           </Grid>

           <Grid  item  xs={12} sm={6} md={4}  key={2}>
                <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src="/images/about/about-02.png"  />
                <Card.Body>
                    <Card.Title><h2>Vission</h2></Card.Title> 
                    <p>
                        To be a leading and trusted provider of custom designed furniture to reach sustainable, quality growth by setting up as the premier
                         <strong> "Made in Ethiopia" </strong>Furniture works in our markets by providing world class 
                         manufacturing of best quality products and superior customer service.
                     </p> 
                </Card.Body>
               </Card> 
           </Grid>

           <Grid  item  xs={12} sm={6} md={4}  key={3}>
                  <Card style={{ width: '100%' }}>
                    <Card.Img variant="top" src="/images/about/about-03.png" />
                    <Card.Body>
                        <Card.Title><h2>Values</h2></Card.Title> 
                        <ul className="value-list">
                            <li><FaCheckSquare style={styles.icon}/>  Customer Focus</li>
                            <li><FaCheckSquare style={styles.icon} />  Honesty and integrity</li>
                            <li><FaCheckSquare style={styles.icon} />  Passion and Conscientiousness</li>
                            <li><FaCheckSquare style={styles.icon} />  Strong sense of Urgency,*Accountability</li>
                            <li><FaCheckSquare style={styles.icon} />  Humility and Respect</li> 
                        </ul> 
                   </Card.Body>
                  </Card> 
           </Grid>
     </Grid> 
    );
}

export default AboutCard;