import React from 'react';
import '../App.css'; 
import HeroSection from '../components/HeroSection';
import ItroParagraph from '../components/IntroParagraph';
import HomeProducts from '../components/HomeProducts'; 
import News from '../components/News';


const styles = {
  content: { 
    padding: "20px",
    backgroundColor:"#fff"
  }
};
function Home() {
  return (
    <div className="row">
      <HeroSection />
      <ItroParagraph/> 
      <HomeProducts/>  
      <div className="row" style={styles.content}></div>
      <News title={"News and Updates"} />
    </div>
  );
} export default Home;