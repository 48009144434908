import * as React from 'react';    
import './IntroParagraph.css'; 
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

export default function ItroParagraph() {
  return (  
   <div className="row info-container"> 
      <Card>
        <Card.Header>
           <h3>Who We Are?</h3>  
        </Card.Header> 
        <Card.Body> 
          <Row>
            <Col xs={12} md={6}>
              <Image src="/images/brand/brand-01.jpg" fluid />
            </Col>
            <Col xs={12} md={6} className="intro-text"> 
            <p>
            Mina Furniture is a company established 12 years ago by Ethiopians with a 
            vision to be a leading supplier of high quality furniture in East Africa. 
            We are a pioneer in the industry offering the market greate choice for innovated and ergonomic designs as well as environmentally
            friendly furniture.
            </p>
            <p> 
               Since our humble inception in 2001 the group has grown from strength to strength. Mina Furniture started its establishment in Addis Ababa around mesalemya (bilal masjid) street and hence its name.from the very beginning the group has focused on its core principles of customer service ,quality,value for money and innovation and it is these principles that have enabled the group to gain the trust of customer.
            </p>
            <p>
                We are here now after facing many challenges. world is ever-changing, 
                in many ways for the better. As Mina, we are trying our best to be the best provider for this generation. 
                Mina further has always stood for quality with best service. It continues to use quality raw materials, 
                including heavier (thicker) woods than most of our competitors and designs that incorporate more forming for added strength,
                durability and aesthetics. As a result, we believe our products are among the best on the market. More importantly,
                  our customers tell us our products are among the best on the market!!  
             </p>
          
            </Col>
          </Row>
        </Card.Body>
    </Card>
 
   </div>
  );
}
 