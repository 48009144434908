import * as React from 'react';  
import CardContent from '@mui/material/CardContent'; 
import Grid from '@mui/material/Grid';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './HomeProducts.css'; 
import ReactImageMagnify from 'react-image-magnify';
import data from '../data/products.json'; 
import { CardActions } from '@mui/material'; 

import Row from 'react-bootstrap/Row';  
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';


export default function HomeProducts() {
  // const [value, setValue] = React.useState('one');


  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return ( 
    <div className="product-section">  
      <Card>
        <Card.Header>
           <h3>Our Products</h3>  
        </Card.Header> 
        <Card.Body> 
          <Row> 
            <Col className="intro-text"> 
            <p>
            On manufacturing we have continued to invest in new technology 
            and machinery to continue its popular pace with new designs and styles to suit 
             clients need in particular, we have invested in post forming machinery. 
            Technology to work with corian material as well as other woodworking machines.
            </p>
            <Tabs>
          <TabList>
            <Tab>Sofa</Tab>
            <Tab>Bed</Tab>
            <Tab>Dining Table</Tab>
            <Tab>Coffee Table</Tab> 
          </TabList>

          <TabPanel>
          <Grid container spacing={4} className="gridContainer">  
           {data.sofa.map((value, index) => { 
              return  <Grid  item  xs={12} sm={6} md={4}  key={index}>
              <Card variant="outlined">
              <React.Fragment>
                <CardContent>
                <ReactImageMagnify {...{
                                    smallImage: {
                                        alt: 'Wristwatch by Ted Baker London',
                                        isFluidWidth: true,
                                        src: value.path,
                                        srcSet: "",
                                        sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                                    },
                                    largeImage: {
                                        src: value.path,
                                        width: 1200,
                                        height: 1800
                                    }
                              }} />
                </CardContent> 
                <CardActions>
                  <h4>{value.code}</h4></CardActions>
              </React.Fragment> 
                </Card>  
      </Grid>  
            })} 
          
          </Grid> 
          </TabPanel>
          <TabPanel>
          <Grid container spacing={4} className="gridContainer">  
        {data.bed.map((value, index) => { 
        return  <Grid  item  xs={12} sm={6} md={4}  key={index}>
          <Card variant="outlined">
              <React.Fragment>
                <CardContent>
                  <ReactImageMagnify {...{
                                      smallImage: {
                                          alt: 'Wristwatch by Ted Baker London',
                                          isFluidWidth: true,
                                          src: value.path,
                                          srcSet: "",
                                          sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                                      },
                                      largeImage: {
                                          src: value.path,
                                          width: 1200,
                                          height: 1800
                                      }
                                }} />
                </CardContent> 
                  <CardActions>
                     <h4>{value.code}</h4>
                  </CardActions>
              </React.Fragment> 
            </Card>  
</Grid>  
      })} 
    
    </Grid> 
          </TabPanel>
          <TabPanel>
          <Grid container spacing={4} className="gridContainer"> 


{data.table.map((value, index) => { 
        return  <Grid  item  xs={12} sm={6} md={4}  key={index}>
        <Card variant="outlined">
        <React.Fragment>
          <CardContent>
          <ReactImageMagnify {...{
                              smallImage: {
                                  alt: 'Wristwatch by Ted Baker London',
                                  isFluidWidth: true,
                                  src: value.path,
                                  srcSet: "",
                                  sizes: '(min-height: 480px) 100vh, (max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                              },
                              largeImage: {
                                  src: value.path,
                                  width: 1200,
                                  height: 1800
                              }
                        }} />
          </CardContent> 
          {/* <CardActions>
            <h4>{value.code}</h4>
          </CardActions> */}
        </React.Fragment> 
          </Card>  
</Grid>  
      })} 
    
    </Grid> 
          </TabPanel>
          <TabPanel>
            <h2>Any content 4</h2>
          </TabPanel>
        </Tabs>  
          
            </Col>
          </Row>
        </Card.Body>
    </Card> 
      <div className="home-product"> 
         
        </div>
    </div> 
  );
}