import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar'; 
import WorkIcon from '@mui/icons-material/PinDropSharp';  
import MdPhone from '@mui/icons-material/Phone';
import Chip from '@mui/material/Chip';
import { yellow } from '@mui/material/colors';
import { FaGripLinesVertical } from "react-icons/fa";

const styles = {
    content: { 
      padding: "20px"
    },
    link: {  
            color: "#ffc107"
    },
    contactForm:{
        paddingLeft: "20px"
    },
    separator:{
        paddingTop: "20px"
    }, 
  };

function Address() {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <WorkIcon  sx={{ color: yellow[700] }}/>
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="#1 - Jemo " secondary=" Infront of Hansom Glass Factory, Raha Mall 1st Floor (premium branch)" />
    </ListItem>
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <WorkIcon   sx={{ color: yellow[500] }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="#2 - Gurd Shola" secondary="Infront of Century Mall" />
    </ListItem>
    <ListItem>
      <ListItemAvatar >
        <Avatar >
          <WorkIcon   sx={{ color: yellow[500] }}/>
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="#3 - Jemo 1" secondary="Infront of Hansom Glass Factory, Bereka Building,Ground Floor" />
    </ListItem>
    <hr/>
    
    <ListItem> 
   <a href="tel:0924245151" style={styles.link}> <Chip icon={<MdPhone />} label="092424-5151" /></a> 
   {' '}<FaGripLinesVertical/> 
   <a href="tel:0934345151" style={styles.link}> <Chip icon={<MdPhone />} label="093434-5151" /></a> 
    </ListItem>
    <ListItem> 
   <a href="tel:0938385151" style={styles.link}> <Chip icon={<MdPhone />} label="093838-5151" /></a> 
   {' '}<FaGripLinesVertical/> 
   <a href="tel:0932325151" style={styles.link}> <Chip icon={<MdPhone />} label="093232-5151" /></a> 
    </ListItem>
     
  </List> 
  );
}

export default Address;