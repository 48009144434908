 
import './App.css';
import Navbar from './components/Navbar';
import ScrollWrapper from './components/ScrollWrapper';
import Home from './pages/Home';
import Contactus from './pages/Contactus';
import About from './pages/About';
import NewsPage from './pages/News';
import Products from './pages/Products';
import {BrowserRouter,Redirect, Route, Switch } from "react-router-dom"; 
import ScrollTopArrow from './components/ScrollTopArrow'; 
import Footer from './components/Footer';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';

function App() { 
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
}
  });
     
  return (  
      <BrowserRouter>
       <Navbar/>
      <div className="content fill-window">
        <ScrollWrapper>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/contactus" exact component={Contactus}></Route>
          <Route path="/about" exact component={About}></Route>
          <Route path="/news" exact component={NewsPage}></Route>
          <Route path="/products/:type" exact component={Products}></Route> 
          <Redirect to='/' />
      </Switch>  
        </ScrollWrapper> 
      <Footer/>
      <ScrollTopArrow/> 
      </div>
      </BrowserRouter> 
  );
}

export default App;
