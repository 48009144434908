import '../App.css'; 
import ContactForm from '../components/ContactForm'; 
import Address from '../components/Address'; 
import GoogleMap from '../components/GoogleMap';


const styles = {
    content: { 
      padding: "20px"
    },
    link: {  
            color: "#ffc107"
    },
    contactForm:{
        paddingLeft: "20px"
    },
    separator:{
        paddingTop: "20px"
    }, 
  };

const Contactus = () => { 
         
    return ( 
        <div style={styles.content}>
<div className="row">
            <div className="col-sm-12 col-md-6">
               <h3>Get In Touch</h3>
               <hr/>
            <Address/>
            </div>
            <div className="col-sm-12 col-md-6">

        <h3>Contact Us</h3>
        <hr/>
           <ContactForm/>
            </div>
         
        </div>
        <div className="row">
            <div className="col-md-12">
           <GoogleMap/>
            </div>
            </div>

        </div>
     );
}


 
export default Contactus;