import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";
 
const STYLES = ["btn--primar",'btn--outline'];
const SIZES = ['btn-medium', 'btn--large'];

export const Button =({
  children,
  type,
  onclick,
  buttonStyle,
  buttonSize

}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
  ?buttonStyle
  :STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
 
   return (
     <Link to={{pathname:'https://shopping.minafurniture1.com/'}} target="_blank"  className="btn-mobile">
       <button className={`btn  ${checkButtonStyle} ${checkButtonSize}`}>
         {children}
       </button>
     </Link>

   );
}; 